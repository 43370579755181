const filters = {
  namespaced: true,
  state: {
    status: "",
    conf: {},
    extraGroup: {},
  },

  mutations: {
    FILTER_SAVE(state, { name, criteria }) {
      state.conf[name] = criteria;
      state.status = "saved";
    },
    FILTER_REMOVE(state, name) {
      delete state.conf[name];
      state.status = "removed";
    },
    FILTER_REMOVE_ALL(state) {
      state.conf = {};
      state.status = "removed all";
    },
    FILTER_ERROR(state) {
      state.status = "error";
    },
    FILTER_EXTRA_GROUP_SAVE(state, { name, extra_info }) {
      state.extraGroup[name] = extra_info;
      state.status = "saved";
    },
    FILTER_EXTRA_GROUP_REMOVE(state, name) {
      delete state.extraGroup[name];
      state.status = "removed";
    },
  },

  actions: {
    saveByName({ commit }, obj) {
      // console.debug("filters action saveByName", obj);
      commit("FILTER_SAVE", obj);
    },
    removeByName({ commit }, name) {
      // console.debug("filters action removeByName", name);
      commit("FILTER_REMOVE", name);
    },
    removeAll({ commit }) {
      // console.debug("filters action removeAll");
      commit("FILTER_REMOVE_ALL");
    },
    /* */
    saveExtraGroupByName({ commit }, obj) {
      // console.debug("filters action saveExtraGroupByName", obj);
      commit("FILTER_EXTRA_GROUP_SAVE", obj);
    },
    removeExtraGroupByName({ commit }, name) {
      // console.debug("filters action removeExtraGroupByName", name);
      commit("FILTER_EXTRA_GROUP_REMOVE", name);
    },
  },

  getters: {
    loadByName: (state) => (name) => {
      //name is a parameter for the getter
      // console.debug("filters getter loadByName", name);
      return state.conf[name];
    },
    loadExtraInfoGroupByName: (state) => (name) => {
      //name is a parameter for the getter
      // console.debug("filters getter loadByName", name);
      return state.extraGroup[name];
    },
  },
};
export default filters;

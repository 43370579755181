<template>
  <div id="app">
    <accordion-sidebar v-show="!$store.state.sidebar.locked" />
    <div class="dashboard">
      <custom-header v-if="isLogged"></custom-header>
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
// // import { showSnackbar } from "@/utils/messages";
import AccordionSidebar from "@/components/AccordionSidebar.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CustomHeader,
    AccordionSidebar,
  },
  computed: {
    ...mapGetters("sidebar", ["isNavClose"]),
    ...mapGetters("auth", ["isLogged", "permissions"]),
    ...mapActions("tabs", { removeAllTabs: "removeAll" }),
  },
  methods: {
    preventF5(event) {
      event.preventDefault();
      event.returnValue = "";
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventF5);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Arial, Helvetica, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  //   "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100vh;
  overflow-x: hidden;
}

.dashboard {
  flex: 1;
  margin-left: 0;
  transition: transform 0.2s linear;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

export const getErrorMessage = (error) => {
  // console.debug(JSON.stringify(error));
  let errMsg = error.data && error.data.message ? error.data.message : error;
  // if (error.status === 422) {
  //   // console.debug(JSON.stringify(error.data.errors));
  //   let errorsList = [];
  //   Object.keys(error.data.errors).forEach((key) => {
  //     errorsList.push(error.data.errors[key].join(" | "));
  //   });
  //   errMsg = errorsList.join(" | ");
  // }
  let errorsList;
  const re = /^attribute_(.+)_value/g;
  let field;
  switch (error.status) {
    case 403:
      // override the original message
      if (errMsg.indexOf("Verificare che non siano presenti") < 0)
        errMsg = "Azione non consentita";
      break;
    case 404:
      // error.data.message often is ''...
      errMsg = error.data.message ? errMsg : "Non trovato";
      break;
    case 422:
      // console.debug(JSON.stringify(error.data.errors));
      if (error.data.errors) {
        errorsList = [];
        Object.keys(error.data.errors).forEach((key) => {
          if (key.match(re)) {
            field = re.exec(key)[1];
          } else {
            field = key;
          }
          errorsList.push(`${field}: ${error.data.errors[key].join(" | ")}`);
        });
        if (errorsList.length) {
          errMsg = errorsList.join(" | ");
        }
      }
      break;
  }
  console.info("Error: ", errMsg ? errMsg : "Oops, something went wrong");
  return errMsg ? errMsg : "Oops, something went wrong";
};

// module.exports = {
//   getErrorMessage,
// };

import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/movimenti_prima_nota";
export default {
  index(queryString) {
    return queryString
      ? Repository.get(`${resource}/altri-movimenti?${queryString}`)
      : Repository.get(`${resource}/altri-movimenti`);
  },
  create() {
    return Repository.get(`${resource}/altri-movimenti/crea`);
  },
  store(payload) {
    return Repository.post(`${resource}/altri-movimenti/`, payload);
  },
  edit(id) {
    return Repository.get(`${resource}/altri-movimenti/${id}/edit`);
  },
  update(id, payload) {
    return Repository.put(`${resource}/altri-movimenti/${id}`, payload);
  },
  destroy(id) {
    return Repository.delete(`${resource}/altri-movimenti/${id}`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  sospesi(queryString) {
    return queryString
      ? Repository.get(`${resource}/sospesi?${queryString}`)
      : Repository.get(`${resource}/sospesi`);
  },
  acconti(queryString) {
    return queryString
      ? Repository.get(`${resource}/acconti?${queryString}`)
      : Repository.get(`${resource}/acconti`);
  },
  creaSospeso() {
    return Repository.get(`${resource}/sospesi/crea`);
  },
  inserisciSospeso(payload) {
    return Repository.post(`${resource}/sospesi`, payload);
  },
  sospeso(id) {
    return Repository.get(`${resource}/sospesi/${id}`);
  },
  editaSospeso(id) {
    return Repository.get(`${resource}/sospesi/${id}/edit`);
  },
  aggiornaSospeso(id, payload) {
    return Repository.put(`${resource}/sospesi/${id}`, payload);
  },
  cancellaSospeso(id) {
    return Repository.delete(`${resource}/sospesi/${id}`);
  },
  recuperoSospesi(id) {
    return Repository.get(`${resource}/sospesi/${id}/recuperi`);
  },
  aggiungiRecupero(idSospeso, payload) {
    return Repository.post(
      `${resource}/sospesi/${idSospeso}/recuperi/`,
      payload
    );
  },
  aggiornaRecupero(idSospeso, id, payload) {
    return Repository.put(
      `${resource}/sospesi/${idSospeso}/recuperi/${id}`,
      payload
    );
  },
  cancellaRecupero(idSospeso, id) {
    return Repository.delete(`${resource}/sospesi/${idSospeso}/recuperi/${id}`);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  summary(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}/saldo-conto?${queryString}`)
      : Repository.get(`${resource}/saldo-conto`);
  },
  listaMovimenti(queryString) {
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  bulk_update(payload) {
    return Repository.put(`${resource}/sospesi/chiusura-massiva`, payload);
  },
  acconto(id) {
    return Repository.get(`${resource}/acconti/${id}`);
  },
  inserisciAcconto(payload) {
    return Repository.post(`${resource}/acconti`, payload);
  },
  editaAcconto(id) {
    return Repository.get(`${resource}/acconti/${id}/edit`);
  },
  aggiornaAcconto(id, payload) {
    return Repository.put(`${resource}/acconti/${id}`, payload);
  },
  cancellaAcconto(id) {
    return Repository.delete(`${resource}/acconti/${id}`);
  },
  recuperoAcconti(id) {
    return Repository.get(`${resource}/acconti/${id}/recuperi`);
  },
  aggiungiRecuperoAcconto(idAcconto, payload) {
    return Repository.post(
      `${resource}/acconti/${idAcconto}/recuperi/`,
      payload
    );
  },
  aggiornaRecuperoAcconto(idAcconto, id, payload) {
    return Repository.put(
      `${resource}/acconti/${idAcconto}/recuperi/${id}`,
      payload
    );
  },
  cancellaRecuperoAcconto(idAcconto, id) {
    return Repository.delete(`${resource}/acconti/${idAcconto}/recuperi/${id}`);
  },
  chiusuraMassivaAcconti(payload) {
    return Repository.put(`${resource}/acconti/chiusura-massiva`, payload);
  },
};

export default [
  {
    path: "/insurance-ancillaries",
    name: "module.TIT.TINS",
    meta: {
      auth: true,
      module: "TIT",
      submodule: "TINS",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "insurance-ancillaries" */ "@/views/insurance-ancillaries/index.vue"
      ),
  },
  {
    path: "/insurance-ancillaries/:id/edit",
    name: "insurance_ancillaries.edit",
    meta: {
      auth: true,
      module: "TIT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-ancillaries" */ "@/views/insurance-ancillaries/edit.vue"
      ),
  },
  {
    path: "/insurance-ancillaries-create",
    name: "insurance_ancillaries.create",
    meta: {
      auth: true,
      module: "TIT",
      submodule: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "insurance-ancillaries" */ "@/views/insurance-ancillaries/create.vue"
      ),
  },
];

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-env":_vm.computeEnv}},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"lisaweb-navbar"}},[_c('burger'),_vm._v(" "),(_vm.pTitle)?_c('span',{staticClass:"page-title",staticStyle:{"color":"white","margin":"0 50px 0 0","font-size":"16px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.pTitle.title ? _vm.pTitle.title : _vm.pTitle.text))]):_vm._e(),_vm._v(" "),(_vm.canVerb('tags', 'index'))?_c('b-navbar-nav',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}},[_c('b-nav-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-input-group',{staticClass:"search-bar",style:({ 'background-color': _vm.isLoading ? 'grey' : 'inherit' }),attrs:{"size":"sm"}},[_c('b-form-input',{style:({
              cursor: _vm.isLoading ? 'progress' : 'text',
              'background-color': _vm.isLoading ? 'grey' : '',
            }),attrs:{"autocomplete":"off","type":"search","placeholder":"Inserisci un criterio di ricerca","size":"sm","disabled":_vm.isLoading,"title":_vm.isLoading ? 'Ricerca in corso...' : ''},on:{"keypress":_vm.getSomething},model:{value:(_vm.something),callback:function ($$v) {_vm.something=$$v},expression:"something"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"search-bar-icon",style:({
              cursor: _vm.isLoading ? 'progress' : 'pointer',
              'background-color': _vm.isLoading ? 'grey' : '',
            }),attrs:{"is-text":"","title":_vm.isLoading ? 'Ricerca in corso...' : 'Cerca'}},[_c('b-icon',{attrs:{"icon":"search"},on:{"click":function($event){!_vm.isLoading && _vm.onFind()}}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('b-navbar-nav',{staticClass:"ml-auto",staticStyle:{"align-items":"center"}},[_c('b-nav-text',[_vm._v("Agenzia: "),_c('b',[_vm._v(_vm._s(_vm.settings.formatted_title))])]),_vm._v(" "),(_vm.isLogged)?_c('b-nav-item-dropdown',{attrs:{"right":""}},[_c('template',{slot:"button-content"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"sm","variant":"light"}}),_vm._v(" "),_c('span',{staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.user.username))])],1),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.signoff()}}},[_vm._v("Esci")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openChangePwdModal()}}},[_vm._v("Cambia Password")])],2):_vm._e()],1)],1),_vm._v(" "),_c('div',[_c('search-results-modal',{attrs:{"results":_vm.results,"term":_vm.something},on:{"close":_vm.onClose}})],1),_vm._v(" "),_c('div',[_c('change-pwd-modal',{attrs:{"title":"Cambia password"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default [
  {
    path: "/report",
    name: "report.index",
    meta: {
      auth: true,
      module: "module.REPORT",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(/* webpackChunkName: "report" */ "@/views/report/Management.vue"),
  },
];

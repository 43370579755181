export default [
  {
    path: "/analysis",
    name: "analysis.index",
    meta: {
      auth: true,
      module: "analysis",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
      // keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ "@/views/analysis/creation/index.vue"
      ),
  },
  {
    path: "/analysis/data",
    name: "analysis_data.index",
    meta: {
      auth: true,
      module: "analysis",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
      // keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ "@/views/analysis/data/index.vue"
      ),
  },
  {
    path: "/analysis/results",
    name: "analysis_result.index",
    meta: {
      auth: true,
      module: "analysis",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
      // keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ "@/views/analysis/result/index.vue"
      ),
  },
  {
    path: "/analysis/data-crossing",
    name: "analysis_data_crossing.index",
    meta: {
      auth: true,
      module: "analysis",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
      // keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ "@/views/analysis/data-crossing/index.vue"
      ),
  },
  {
    path: "/analysis/data-crossing/results",
    name: "analysis_data_crossing_result.index",
    meta: {
      auth: true,
      module: "analysis",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
      // keepFilter: true, // prevent removeFilterByName on route change. default is false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ "@/views/analysis/data-crossing-result/index.vue"
      ),
  },
];

import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/analyses";
export default {
  get_configuration() {
    // get the create form
    return Repository.get(`${resource}/configuration`);
  },
  execute(payload, queryString) {
    return queryString
      ? Repository.post(`${resource}/execute?${queryString}`, payload)
      : Repository.post(`${resource}/execute`, payload);
  },
  get_methods(queryString) {
    // get the list of saved methods
    return queryString
      ? Repository.get(`${resource}/method?${queryString}`)
      : Repository.get(`${resource}/method`);
  },
  view_method(id) {
    // get the configuration of saved methods, with id = {$id}
    return Repository.get(`${resource}/method/${id}`);
  },
  save_method(payload) {
    // save a new method
    return Repository.post(`${resource}/method`, payload);
  },
  update_method(id, payload) {
    // override the existing method, with id = {$id}
    return Repository.put(`${resource}/method/${id}`, payload);
  },
  destroy_method(id) {
    // destroy a method
    return Repository.delete(`${resource}/method/${id}`);
  },
  save_data(payload) {
    // save a new set of data
    return Repository.post(`${resource}/data`, payload);
  },
  destroy_data(id) {
    // destroy a set of data
    return Repository.delete(`${resource}/data/${id}`);
  },
  download_from_data(id) {
    // download a set of data in CSV format
    return Repository.post(`${resource}/data/${id}/download`);
  },
  view_data(id) {
    // get a set of data, with id = {$id}
    return Repository.get(`${resource}/data/${id}`);
  },
  update_info_data(id, payload) {
    // override the existing set of data, with id = {$id}
    return Repository.put(`${resource}/data/${id}`, payload);
  },
  preview(id) {
    return Repository.get(`${resource}/data/${id}/preview`);
  },
  paginated(id, queryString) {
    return queryString
      ? Repository.get(`${resource}/data/${id}/paginated?${queryString}`)
      : Repository.get(`${resource}/data/${id}/paginated`);
  },
  download(payload) {
    // download a set of data in CSV format
    return Repository.post(`${resource}/download`, payload);
  },
  index(queryString) {
    // get the list of results data
    return queryString
      ? Repository.get(`${resource}/data?${queryString}`)
      : Repository.get(`${resource}/data`);
  },
  data_cross(payload) {
    return Repository.post(`${resource}/merge/preview`, payload);
  },
  data_cross_paginated(payload, queryString) {
    return queryString
      ? Repository.post(`${resource}/merge/paginated?${queryString}`, payload)
      : Repository.post(`${resource}/merge/paginated`, payload);
  },
  data_cross_download(payload) {
    return Repository.post(`${resource}/merge/download`, payload);
  },
  data_cross_save(payload) {
    return Repository.post(`${resource}/merge/data/save`, payload);
  },
  /*
  edit(id) {
    // get the edit form
    return Repository.get(`${resource}/${id}/edit`);
  },
  validate(field, payload) {
    return Repository.post(`${resource}/validation/${field}`, payload);
  },
  show(id, relationString) {
    return relationString
      ? Repository.get(`${resource}/${id}/${relationString}`)
      : Repository.get(`${resource}/${id}`);
  },
  store(payload) {
    // save the item
    return Repository.post(`${resource}`, payload);
  },
  update(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    // delete the item
    return Repository.delete(`${resource}/${id}`);
  },
  pivot_store(id, relation, payload) {
    return Repository.post(`${resource}/${id}/pivot/${relation}`, payload);
  },
  pivot_update(id, relation, payload) {
    return Repository.put(`${resource}/${id}/pivot/${relation}`, payload);
  },
  delete_attribute(id, attribute) {
    // update the item
    return Repository.delete(`${resource}/${id}/attribute/${attribute}`);
  },
  update_without_detach(id, payload) {
    // update the item
    return Repository.put(`${resource}/${id}/without-detach/`, payload);
  },
  */
};

<template>
  <div :data-env="computeEnv">
    <b-navbar toggleable="lg" type="dark" variant="lisaweb-navbar">
      <burger></burger>
      <span
        class="page-title"
        style="
          color: white;
          margin: 0 50px 0 0;
          font-size: 16px;
          font-weight: bold;
        "
        v-if="pTitle"
        >{{ pTitle.title ? pTitle.title : pTitle.text }}</span
      >
      <b-navbar-nav
        v-if="canVerb('tags', 'index')"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <b-nav-form v-on:submit.prevent="">
          <b-input-group
            size="sm"
            class="search-bar"
            :style="{ 'background-color': isLoading ? 'grey' : 'inherit' }"
          >
            <b-form-input
              autocomplete="off"
              type="search"
              placeholder="Inserisci un criterio di ricerca"
              size="sm"
              class=""
              v-model="something"
              @keypress="getSomething"
              :disabled="isLoading"
              :style="{
                cursor: isLoading ? 'progress' : 'text',
                'background-color': isLoading ? 'grey' : '',
              }"
              :title="isLoading ? 'Ricerca in corso...' : ''"
            >
            </b-form-input>
            <b-input-group-append
              is-text
              class="search-bar-icon"
              :style="{
                cursor: isLoading ? 'progress' : 'pointer',
                'background-color': isLoading ? 'grey' : '',
              }"
              :title="isLoading ? 'Ricerca in corso...' : 'Cerca'"
            >
              <b-icon icon="search" @click="!isLoading && onFind()"></b-icon>
            </b-input-group-append>
            <!-- https://forum.framework7.io/t/vue-pressing-enter-key-in-input-causes-app-to-reload/2585 -->
            <!-- <b-form-input
              style="display: none"
              type="search"
              placeholder="devo metterlo altrimenti con 1 solo input nel form il key enter cambia pagina"
              size="sm"
            >
            </b-form-input> -->
            <!-- or simply add v-on:submit.prevent -->
          </b-input-group>
        </b-nav-form>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" style="align-items: center">
        <b-nav-text
          >Agenzia: <b>{{ settings.formatted_title }}</b>
        </b-nav-text>
        <b-nav-item-dropdown right v-if="isLogged">
          <template slot="button-content">
            <!-- <b class="mr-3">Agenzia: {{ settings.formatted_title}}</b> -->
            <!-- <b-avatar variant="light"></b-avatar> -->
            <b-avatar size="sm" variant="light" class="mr-1"></b-avatar>
            <span class="mr-auto">{{ user.username }}</span>
          </template>
          <b-dropdown-item @click="signoff()">Esci</b-dropdown-item>
          <b-dropdown-item @click="openChangePwdModal()"
            >Cambia Password</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <div>
      <search-results-modal
        :results="results"
        :term="something"
        @close="onClose"
      />
    </div>
    <div>
      <change-pwd-modal title="Cambia password" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Burger from "@/components/Burger.vue";
import SearchResultsModal from "@/components/modals/searchResults";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { canVerb } from "@/utils/permissions";
import ChangePwdModal from "@/components/modals/changePwd";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
export default {
  mixins: [StorageGetterMixin],
  components: {
    Burger,
    SearchResultsModal,
    ChangePwdModal,
  },
  props: ["pTitle"],
  data() {
    return {
      something: "",
      isLoading: false,
      results: {},
    };
  },
  methods: {
    canVerb,
    getSomething(event) {
      if (this.isLoading) {
        this.$showSnackbar({
          preset: "info",
          text: "Un altra ricerca è in corso. Attendere...",
          duration: 1000,
        });
        return;
      }
      if (event.which === 13) {
        this.onFind();
      }
    },
    getUniqueBy(arr, prop) {
      const set = new Set();
      return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
    },
    groupByFound(array) {
      let grouped = {
        registry: [],
        agency_contact: [],
        insurance_policy: [],
        claim: [],
      };
      // array.forEach((item) => {
      //   // ogni object (found) ha solo 1 entity (NO!!!)
      //   let found = item.found.find((e) => e[Object.keys(e)].length);
      //   let entity = Object.keys(found)[0];
      //   let results = found[entity];
      //   let obj = {
      //     id: item.id,
      //     affinity: item.affinity,
      //     occurrency: item.occurrency,
      //     term: item.term,
      //     found: results,
      //   };
      //   grouped[entity] = grouped[entity].concat(obj);
      // });
      array.forEach((item) => {
        // ogni object (found) può contenere più entity
        let founds = item.found.filter((e) => e[Object.keys(e)].length);
        let entities = Object.values(founds);
        entities.forEach((e) => {
          let entity = Object.keys(e)[0];
          if (e[entity].length) {
            let results = e[entity];
            let obj = {
              // id: item.id,
              affinity: item.affinity,
              occurrency: item.occurrency,
              term: item.term,
              found: results,
            };
            // deplicates detection
            let found_ids = results.map((e) => e.id);
            let grouped_ids = [];
            for (const g of grouped[entity]) {
              grouped_ids = grouped_ids.concat(g.found.map((e) => e.id));
            }

            if (!grouped_ids.length) {
              // è la prima iterazione: semplicemente aggiungo
              grouped[entity] = grouped[entity].concat(obj);
            } else {
              // per ogni nuovo found_id, verifico se era già stato gruppato..
              for (const id of found_ids) {
                if (grouped_ids.includes(id)) {
                  // ..rimuovo il duplicato..
                  obj.found = obj.found.filter((e) => e.id != id);
                }
              }
              // ..e concateno, se found.length
              if (obj.found.length) {
                grouped[entity] = grouped[entity].concat(obj);
              }
            }
          }
        });
      });
      // // // deplicates detection
      // // console.log("grouped:", grouped);
      // // for (const entity in grouped) {
      // //   allFound = grouped[entity].map((e) => e.found);
      // //   console.log(`${entity}: ${grouped[entity]}`);
      // //   let arr = grouped[entity];
      // //   grouped[entity] = this.getUniqueBy(grouped[entity], "id");
      // // }
      // // console.log("grouped unique:", grouped);
      return grouped;
    },
    onFind() {
      /* */
      console.log("onFind");
      const path = "auth/";
      const resources = [];
      if (!this.hasLoadedResource("councils"))
        resources.push(
          this.$store
            .dispatch(`${path}councils`)
            .then(() => {})
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            })
        );
      if (!this.hasLoadedResource("provinces"))
        resources.push(
          this.$store
            .dispatch(`${path}provinces`)
            .then(() => {})
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            })
        );
      if (!this.hasLoadedResource("countries"))
        resources.push(
          this.$store
            .dispatch(`${path}countries`)
            .then(() => {})
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            })
        );
      if (resources.length) {
        this.getResources(resources)
          .then(() => {})
          .catch((error) => {
            console.log("One or more promises crashed... :|");
            this.onFailure(error);
          })
          .finally(() => {
            this.isLoading = false;
            this.onActualFind();
          });
      } else {
        this.isLoading = false;
        this.onActualFind();
      }
      /* */
    },
    onActualFind() {
      console.log("onActualFind");
      if (this.something.trim()) {
        if (this.something.trim().length < 3) {
          this.$showSnackbar({
            preset: "error",
            text: "Digitare almeno 3 caratteri",
          });
          return;
        }
        // NOTE: inviare tutti i termini separati da virgola
        // let term = this.something.replace(/(\s)+/g, ",");
        let term = this.something;
        const IndexRepository = RepositoryFactory.get("index");
        this.isLoading = true;
        IndexRepository.tags(term)
          .then((response) => {
            this.results = this.groupByFound(response.data.data);
            this.$bvModal.show("searchResults");
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            // this.isLoading = false;
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$showSnackbar({
          preset: "error",
          text: `Digitare un criterio.`,
          duration: 1000,
        });
      }
    },
    onClose() {
      // clear the search criteria...
      this.something = null;
    },
    signoff() {
      console.info("signing off...");
      this.$emit("signoff", null);
    },
    openChangePwdModal() {
      this.$bvModal.show("changePwd");
    },
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "user", "settings"]),
    computeEnv() {
      return process.env.VUE_APP_TEST;
    },
    // cliVer() {
    //   return process.env.VUE_APP_VERSION || "0";
    // },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: auto;
}
@media (min-width: 999px) {
  .navbar {
    height: 3rem;
  }
}

.page-title {
  position: relative;
  top: 1px;
}

// :deep(.dropdown-menu) {
//   top: 90% !important;
//   padding: 0.25rem 0 !important;
// }

:deep(.nav-item) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-group-text .b-icon.bi {
  color: white;
  width: 30px;
}
.input-group-text {
  background-color: #41352c;
  border: none;
}
.search-bar,
.search-bar > .form-control.form-control-sm {
  background-color: #41352c;
  color: white;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  font-size: 12px;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  font-size: 12px;
  opacity: 1; /* Firefox */
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
:deep(.version) {
  // position: absolute;
  // top: 2px;
  // left: 130px;
  font-size: smaller;
}

input:placeholder-shown + div {
  pointer-events: none;
  touch-action: none;
  cursor: not-allowed;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>

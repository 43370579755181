import { mapGetters } from "vuex";
import {
  toLocaleCurrency,
  toLocaleDecimal,
  toLocaleInteger,
} from "@/utils/strings";
import { getInputType } from "@/utils/forms";
import { canVerb, canModule } from "@/utils/permissions";
export default {
  data: () => ({
    allFields: [],
    actions: [], // used to determine which action buttons display in Actions table cell
    actPos: "first", // actions position (default is first): set it to "last" in table's data to change position
  }),
  methods: {
    convertFromCustomAttributesToFields(array) {
      let ret = [];
      if (!array) return ret;
      array.forEach((element) => {
        // console.log("Element: ", element.key, element);
        if (element.is_customizable && element.is_customizable === "N") return;
        // if (element.last_delete) return;
        if (element.is_hidden && element.is_hidden === "Y") return;
        let e = {
          // key: element.key, // REVIEW: here check if key should be: `attributables.${element.key}`
          key: `attributables.${element.key}`,
          label: element.text,
          sortable: element.is_sortable === "Y" ? true : false,
          thClass: "align-middle", // vertically center th label
          formatter: null,
        };
        // REVIEW sarebbe bello se un campo custom relativo ad una valuta, venisse indicato come type="currency"
        //  e non type="text", in questo modo nel mondo perfetto sarebbe possibile applicare
        // la formattazione -> formatter: (value) => toLocaleCurrency(value),
        // sarebbe ancor più bello se il codice qui sotto facesse quello che ci si aspetterebbe, cioè applicare
        // la formattazione al valore del campo custom :-((
        // if (element.type === "text") {
        //   e["formatter"] = (value) => toLocaleCurrency(value);
        //   // e["formatter"] = function(value) {
        //   //   return toLocaleCurrency(value);
        //   // };
        // }
        let fn = null;
        switch (getInputType(element)) {
          // case "text":
          //   fn = (value) => toLocaleCurrency(value);
          //   break;
          case "currency":
            fn = (value) => toLocaleCurrency(value);
            break;
          case "number":
            fn = (value) => toLocaleInteger(value);
            break;
          case "decimal":
            fn = (value) => toLocaleDecimal(value);
            break;
          case "radio":
            fn = (value) => (value === "Y" ? "SI" : "NO");
        }
        if (fn) {
          e.formatter = fn;
        }
        // if (element.is_sortable === "Y") {
        if (e.sortable) {
          e["sortKey"] = `byAttribute.${element.key}`;
        }
        ret.push(e);
      });
      return ret;
    },
    ...mapGetters("auth", ["customAttributes"]),
  },
  // computed: {
  //   ...mapGetters("auth", ["permissions"]),
  // },
  mounted() {},
  beforeMount() {
    // console.debug(`beforeMount: repository = ${this.repository}`);
    // console.debug(`beforeMount: resource = ${this.resource}`);
    // console.debug(
    //   "beforeMount: this.permissions.includes(`resources.${this.resource}.edit`):",
    //   this.permissions.includes(`resources.${this.resource}.edit`)
    // );
    // if (!this.repository) {
    //   console.info("CustomFieldsAndActionsMixin: missing resource");
    // }

    if (
      this.onlyActions.includes("edit") &&
      canVerb(this.resource, "update")
      // this.permissions.includes(`resources.${this.resource}.edit`)
    ) {
      this.actions.push("edit");
    }

    if (
      this.onlyActions.includes("details") &&
      canVerb(this.resource, "show")
      // this.permissions.includes(`resources.${this.resource}.show`)
    ) {
      this.actions.push("details");
    }

    if (
      this.onlyActions.includes("infomodal") &&
      canVerb(this.resource, "show")
      // this.permissions.includes(`resources.${this.resource}.show`)
    ) {
      this.actions.push("infomodal");
    }

    // destroy
    if (
      this.onlyActions.includes("destroy") &&
      canVerb(this.resource, "destroy")
      // this.permissions.includes(`resources.${this.resource}.destroy`)
    ) {
      this.actions.push("destroy");
    }

    // lisa_for_apps
    if (
      this.onlyActions.includes("lisa_for_apps") &&
      canModule("module.HOME.LAPP") &&
      canVerb("lisa_for_apps", "index")
    ) {
      this.actions.push("lisa_for_apps");
    }

    // reports
    if (this.onlyActions.includes("reports") && canVerb("reports", "edit")) {
      this.actions.push("reports");
    }

    if (!this.repository) {
      // console.info("CustomFieldsAndActionsMixin: missing repository");
      return;
    }
    // transform custom field (remove unuseful properties like is_*)
    let customAttributes = this.convertFromCustomAttributesToFields(
      this.customAttributes()(this.repository)
    );

    // apply thClass: 'align-middle' to all fields
    // let fields = this.fields.map((obj) => ({
    //   ...obj,
    //   thClass: "align-middle", // vertically center th label
    // }));
    for (let field of this.fields) {
      field.thClass = "align-middle"; // vertically center th label
    }

    if (!this.noCustom) {
      // append custom fields to static fields
      this.allFields = this.fields.concat(customAttributes);
    } else {
      // static fields only
      this.allFields = this.fields;
    }

    if (this.actions.length) {
      switch (this.actPos) {
        // case "first":

        // break;
        case "last":
          this.allFields.push({
            key: "actions",
            stickyColumn: true,
            label: "Azioni",
            // thClass: "w-25",
            thStyle: {
              width: `${20 * this.actions.length}px`,
            },
            thClass: "align-middle", // vertically center th label
            // tdClass: "transparent", // when using rowClass to highlight a row ef in orange, or background would be white.
            // SIDE EFFECT: text scrolls behind
            tdClass: "bg-light actions", // when using rowClass to highlight a row ef in orange, or background would be white
          });
          break;
        default:
          this.allFields.unshift({
            key: "actions",
            stickyColumn: true,
            label: "Azioni",
            // thClass: "w-25",
            thStyle: {
              width: `${20 * this.actions.length}px`,
            },
            thClass: "align-middle", // vertically center th label
            // tdClass: "transparent", // when using rowClass to highlight a row ef in orange, or background would be white.
            // SIDE EFFECT: text scrolls behind
            tdClass: "bg-light actions", // when using rowClass to highlight a row ef in orange, or background would be white
          });
      }
    } else {
      this.allFields.unshift({
        key: "actions",
        stickyColumn: true,
        label: "Azioni",
        thStyle: {
          width: `${20 * this.actions.length}px`,
        },
        thClass: "align-middle",
        tdClass: "bg-light actions",
      });
    }
  },
};
